import Vue from "vue";
import Vuex from "vuex";

import { IAppState, App } from "./modules/App";
import { IUserState, User } from "./modules/User";


Vue.use(Vuex);

export interface IRootState {
    app: IAppState;
    user: IUserState;
}

const Store = new Vuex.Store<IRootState>({
    strict: process.env.NODE_ENV !== "production",
});


export default Store;

