import Vue, { CreateElement } from "vue";

// Plugins
import Plugins from "@/plugins/";

// Middlewares
import Middlewares from "@/middlewares";

// Views
import App from "@/App.vue";

import Router from "@/router";
import Store from "@/store/";


class Main {
    constructor() {
        this.LoadComponents();
        this.LoadConfig();

        Vue.use(this.plugins);
        Vue.use(this.middlewares);

        this.App = new Vue({
            router: Router,
            store: Store,
            render: (h: CreateElement) => h(App),
        }).$mount("#app");
    }


    public App: Vue;
    public IsDev: boolean = (process.env.NODE_ENV === "development");

    private plugins: Plugins = new Plugins(Router, Store);
    private middlewares: Middlewares = new Middlewares(Router, Store);


    private LoadComponents() {
        // Vue.component("...", ...);
    }

    private LoadConfig() {

        Vue.config.productionTip = false;
        Vue.config.silent = !this.IsDev;
        Vue.config.devtools = this.IsDev;
        Vue.config.performance = this.IsDev;
        Vue.config.errorHandler = (error, vm, info) => {
            console.error(info, error); // tslint:disable-line: no-console
        };
    }
}

export default new Main();
