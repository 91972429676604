import Vue from "vue";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
// import "@Styles/element-ui-variables.scss";


export default (vue: typeof Vue): void => {
    Vue.use(ElementUI, { locale });
};
