import Vue from "vue";
import { RawLocation } from "vue-router";
import { AxiosError } from "axios";

import Router from "@/router";
import Store from "@/store/";
import * as Types from "@/store/Types";


export default (vue: typeof Vue, router: typeof Router, store: typeof Store): void => {
    vue.Api.Main.Client.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        switch ((error.response.data && error.response.data.status) || error.response.status) {
            case "UNAUTHORIZED":
            case 401:
                if (router.currentRoute.name !== "login") {
                    store.commit(Types.SET_AUTH, undefined);
                    router.replace({ name: "login", query: { redirect: router.currentRoute.name || "home", error: "401" } });
                }
            default:
                return Promise.reject(error);
        }
    });

    router.beforeEach(async (to, from, next) => {
        let nextLocation: RawLocation | false | undefined;

        // Handle logout
        if (to.name === "logout") {
            await vue.Api.Auth.Logout();
            nextLocation = { name: (from.name && !from.meta.requiresAuth) ? from.name : "login" };
        } else if (to.meta.requiresUnauth) {    // Requires unauthorized
            if (store.state.user.Auth.Token) nextLocation = { name: (from.name && !from.meta.requiresUnauth) ? from.name : "home" };
        } else if (to.meta.requiresAuth) {      // Requires authorized
            if (!store.state.user.Auth.Token) nextLocation = { name: "login", query: { redirect: to.name || "home" } };
        }

        next(nextLocation);
    });


    vue.Api.Auth.CheckToken(true);
    vue.Api.Auth.SetToken();
};
