import Vue from "vue";
import axios, { AxiosError } from "axios";

import Router from "@/router";
import Store from "@/store/";

// Services
import { ApiService, AuthService } from "@/services/";


export default (vue: typeof Vue, router: typeof Router, store: typeof Store): void => {
    vue.prototype.$Api = vue.Api = {
        Main: new ApiService(vue, router, store),
        Auth: new AuthService(vue, router, store),
    };
};
