import Vue from "vue";
import VueLogger from "vuejs-logger";


export default (vue: typeof Vue): void => {
    Vue.use(VueLogger, {
        logLevel : (vue.config.silent) ? "fatal" : "debug",
        showLogLevel : true,
        showMethodName : true,
        showConsoleColors: true,
    });
};
