import Vue from "vue";

import Router from "@/router";
import Store from "@/store/";


// Middlewares
import APIMiddleware from "./APIMiddleware";
import AuthMiddleware from "./AuthMiddleware";
import StoreMiddleware from "./StoreMiddleware";


export default class Plugins {
    constructor(router: typeof Router, store: typeof Store) {
        this.router = router;
        this.store = store;
    }

    public readonly router: typeof Router;
    public readonly store: typeof Store;

    public install(vue: typeof Vue) {
        APIMiddleware(vue, this.router, this.store);
        AuthMiddleware(vue, this.router, this.store);
        StoreMiddleware(vue, this.router, this.store);
    }
}
