// import createPersistedState from "vuex-persistedstate";
import { VuexModule, Module, Mutation, Action, getModule } from "vuex-module-decorators";

import store from "@/store";


// tslint:disable-next-line: no-empty-interface
export interface IAppState {
}

@Module({ dynamic: true, store, name: "app" })
export class App extends VuexModule implements IAppState {
    public static LoadPlugins() {
        // createPersistedState({
        //     key: "app",
        //     paths: [
        //         "app",
        //     ],
        // })(store);
    }

}

App.LoadPlugins();


export const AppModule: App = getModule(App);
