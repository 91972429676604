






import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
    },
})
export default class App extends Vue {

}
