import Vue from "vue";

import Router from "@/router";
import Store from "@/store/";

// Plugins
import LoggerPlugin from "./LoggerPlugin";
import ElementUIPlugin from "./ElementUIPlugin";


export default class Plugins {
    constructor(router: typeof Router, store: typeof Store) {
        this.router = router;
        this.store = store;
    }

    public readonly router: typeof Router;
    public readonly store: typeof Store;

    public install(vue: typeof Vue) {
        LoggerPlugin(vue);
        ElementUIPlugin(vue);
    }
}
