import Vue from "vue";
import Router from "vue-router";


Vue.use(Router);

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes: [
        {
            path: "/login",
            name: "login",
            meta: { requiresUnauth: true },
            component: () => import("@/views/Login.vue"),
        },
        {
            path: "/logout",
            name: "logout",
            meta: { hidden: true },
        },
        {
            path: "/",
            name: "home",
            meta: { requiresAuth: true },
            component: () => import("@/views/Main.vue"),
            children: [
                {
                    path: "/monitoring",
                    name: "monitoring",
                    meta: { requiresAuth: true, title: "Мониторинг", icon: "el-icon-setting", permissions: ["full"] },
                    component: () => import("@/views/Monitoring.vue"),
                },
                {
                    path: "/sales",
                    name: "sales",
                    meta: { requiresAuth: true, title: "Продажи", icon: "el-icon-setting", permissions: ["get_sales"] },
                    component: () => import("@/views/Sales.vue"),
                },
                {
                    path: "/prolong/users",
                    name: "prolong_users",
                    meta: { requiresAuth: true, title: "Продления", icon: "el-icon-setting", permissions: ["get_prolong_users"] },
                    component: () => import("@/views/ProlongUsers.vue"),
                },
                {
                    path: "/check/sales",
                    name: "check_sales",
                    meta: { requiresAuth: true, title: "Проверка продаж по обзвону", icon: "el-icon-setting", permissions: ["check_call"] },
                    component: () => import("@/views/CheckSales.vue"),
                },
                {
                    path: "/clients",
                    name: "clients",
                    meta: { requiresAuth: true, title: "Клиенты", icon: "el-icon-setting", permissions: ["manage_client"] },
                    component: () => import("@/views/Clients.vue"),
                },
                {
                    path: "/stats",
                    name: "stats",
                    meta: { requiresAuth: true, title: "Статистика", icon: "el-icon-setting", permissions: ["stats"] },
                    component: () => import("@/views/CheckSales.vue"),
                    children: [
                        {
                            path: "/stats/sales",
                            name: "check_sales3",
                            meta: { requiresAuth: true, title: "Продажи", icon: "el-icon-setting", permissions: ["stats"] },
                            component: () => import("@/views/CheckSales.vue"),
                        },
                        {
                            path: "/stats/segment",
                            name: "check_sales2",
                            meta: { requiresAuth: true, title: "Сегмент", icon: "el-icon-setting", permissions: ["stats"] },
                            component: () => import("@/views/CheckSales.vue"),
                        },
                    ],
                },
                {
                    path: "/dictionary",
                    name: "dictionary",
                    meta: { requiresAuth: true, title: "Марки и Модели", icon: "el-icon-setting", permissions: ["full"] },
                    component: () => import("@/views/Dictionary.vue"),
                },
                {
                    path: "/management",
                    name: "management",
                    meta: { requiresAuth: true, title: "Управление пользователями", icon: "el-icon-setting", permissions: ["full"] },
                    component: () => import("@/views/UserManagement.vue"),
                },
            ],
        },
        {
            name: "404",
            // path: "*",
            path: "/error/404",
            component: () => import("@/views/Error.vue"),
            props: { errorId: 404 },
            meta: { hidden: true },
        },
        {
            name: "*",
            path: "*",
            redirect: "/error/404",
            meta: { hidden: true },
        },
    ],
});
