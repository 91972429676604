import createPersistedState from "vuex-persistedstate";
import { VuexModule, Module, Mutation, Action, getModule } from "vuex-module-decorators";

import store from "@/store";
import * as Types from "../Types";


export interface IUserState {
    Auth: {
        Token?: string;
        Permissions: string[];
    };
}

@Module({ dynamic: true, store, name: "user" })
export class User extends VuexModule implements IUserState {
    public static LoadPlugins() {
        createPersistedState({
            key: "user",
            paths: [
                "user",
            ],
        })(store);
    }

    public Auth: IUserState["Auth"] = {
        Permissions: [],
    };

    @Mutation
    public [Types.SET_AUTH](auth: IUserState["Auth"]) {
        if (!auth) {
            this.Auth = {
                Permissions: [],
            };
            return;
        }

        this.Auth = {
            Token: auth.Token,
            Permissions: auth.Permissions,
        };
    }

    @Action({ commit: Types.SET_AUTH })
    public SetAuth(auth: IUserState["Auth"] | undefined) { return auth; }
}

User.LoadPlugins();


export const UserModule: User = getModule(User);
