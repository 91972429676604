import Vue from "vue";
import axios from "axios";

import Router from "@/router";
import Store from "@/store";
import * as Types from "@/store/Types";


export interface IResponse {
    status: string;
    message?: string;
}

interface ICheckCallResponse extends Array<{
    key: string;
    status: string;
}> { }

export interface IFilterOptions {
    dateRange: any[];
    search?: string;
    page?: number;
    limit?: number;
}

interface ISalesResponse {
    sales: [{
        id: string;
        price: number;
        email: string;
        car: string;
        company: string;
        date: Date;
    }];
}

interface IProlongUsersResponse {
    users: [{
        id: string;
        price: number;
        email: string;
        car: string;
        company: string;
        date: Date;
        brandName: string;
        modelName: string;
        cityName: string;
        fio: string;
    }];
}

interface IClientsResponse extends IResponse {
    result: {
        count: number,
        data: [{
            id: string,
            email: string,
            fullName: string,
            mobileNumber: string,
        }];
    };
}

interface IClientSessionResponse extends IResponse {
    sessionId: string;
}

interface IPolicyDocumentsResponse {
    documents: [IPolicyDocument];
    error?: string;
}

interface IGetUserProlongUrlResponse extends IResponse {
    prolongUrl?: string;
}


export default class ApiService {
    constructor(vue: typeof Vue, router: typeof Router, store: typeof Store) {
        this.vue = vue;
        this.router = router;
        this.store = store;

        this.Configure();
    }

    public Client = axios.create({
        baseURL: process.env.NODE_ENV === "development" ? `http://localhost:8080/api` : "https://osago.one/api",
        timeout: 60000,
        // validateStatus: (status) => {
        //     return status >= 200 && status < 500;
        // },
    });
    private vue: typeof Vue;
    private store: typeof Store;
    private router: typeof Router;
    public Configure(): void {
        // ...
    }

    /**
     * CheckCall
     * @param {any} params
     * @returns {Promise<ICheckCallResponse>}
     */
    public async CheckCall(params: any): Promise<ICheckCallResponse> {
        const res = await this.vue.Api.Main.Client.post<ICheckCallResponse>("/private/tool/call/check", params);

        return res.data;
    }

    /**
     * Get sales
     * @param {string} startDate
     * @param {string} endDate
     * @returns {Promise<ISalesResponse>}
     */
    public async GetSales(startDate: string, endDate: string): Promise<ISalesResponse> {
        const res = await this.vue.Api.Main.Client.get<ISalesResponse>(`/private/tool/sales?startDate=${startDate || ""}&endDate=${endDate || ""}`);

        return res.data;
    }

    /**
     * Get policy documents
     * @param {string} company
     * @param {string} id
     * @param {string} type
     * @returns {Promise<IPolicyDocumentsResponse>}
     */
    public async GetPolicyDocuments(company: string, id: string, type?: string): Promise<IPolicyDocumentsResponse> {
        const res = await this.vue.Api.Main.Client.get<IPolicyDocumentsResponse>(`/${company}/private/agreement/print?id=${id}&type=${type || "eosago"}`);

        return res.data;
    }

    /**
     * Get users for prolong
     * @param {string} startDate
     * @param {string} endDate
     * @returns {Promise<IProlongUsersResponse>}
     */
    public async GetUsersForProlong(startDate: string, endDate: string): Promise<IProlongUsersResponse> {
        const res = await this.vue.Api.Main.Client.get<IProlongUsersResponse>(`/private/tool/prolong/users?startDate=${startDate || ""}&endDate=${endDate || ""}`);

        return res.data;
    }

    /**
     * Get clients
     * @returns {Promise<IClientsResponse>}
     */
    public async GetClients(filter: IFilterOptions = { dateRange: [], page: 0, limit: 40 }): Promise<IClientsResponse> {
        const [dateStart, dateEnd] = filter.dateRange;
        const res = await this.vue.Api.Main.Client.get<IClientsResponse>("/private/tool/client", {
            params: {
                page: filter.page,
                limit: filter.limit,
                search: filter.search,
                dateStart,
                dateEnd,
            },
        });

        return res.data;
    }

    /**
     * Get client session
     * @param {string} email client email
     * @returns {Promise<IClientSessionResponse>}
     */
     public async GetClientSession(email: string): Promise<IClientSessionResponse> {
        const res = await this.vue.Api.Main.Client.get<IClientSessionResponse>(`/private/tool/client/login?email=${email}`);

        return res.data;
    }

    /**
     * Get user prolong url
     * @param {string} userId user id
     * @returns {Promise<IGetUserProlongUrlResponse>}
     */
     public async GetUserProlongUrl(userId: string): Promise<IGetUserProlongUrlResponse> {
        const res = await this.vue.Api.Main.Client.get<IGetUserProlongUrlResponse>(`/private/tool/prolong/user/url?id=${userId}`);

        return res.data;
    }

    public async getModels(name: string, page: number = 0) {
        const res = await this.vue.Api.Main.Client.get(`/private/tool/models`, {
            params: {
                name,
                page,
            },
        });

        return res.data;
    }

    public async getModelStats() {
        const res = await this.vue.Api.Main.Client.get("/private/tool/not-found-models");

        return res.data;
    }

    public async deleteEmail(email: string) {
        const res = await this.vue.Api.Main.Client.post("/private/tool/delete-email", { email });

        return res.data;
    }

    public async editModel(id: string, data: any) {
        const res = await this.vue.Api.Main.Client.put(`/private/tool/models/${id}`, data);

        return res.data;
    }

    public async editBrand(id: string, data: any) {
        const res = await this.vue.Api.Main.Client.put(`/private/tool/brands/${id}`, data);

        return res.data;
    }
}
