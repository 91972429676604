import Vue from "vue";
import axios from "axios";

import Router from "@/router";
import Store from "@/store";
import { IResponse } from "./ApiService";
import * as Types from "@/store/Types";
import { UserModule } from "@/store/modules/User";


interface IAuthResponse extends IResponse {
    token?: string;
    permissions?: string[];
}

export default class AuthService {
    constructor(vue: typeof Vue, router: typeof Router, store: typeof Store) {
        this.vue = vue;
        this.router = router;
        this.store = store;

        this.Configure();
    }

    private vue: typeof Vue;
    private store: typeof Store;
    private router: typeof Router;

    public Configure(): void {
        this.store.subscribe((mutation, state) => {
            if (mutation.type === Types.SET_AUTH) {
                this.SetToken((mutation.payload && mutation.payload.token) ? mutation.payload.token : "");
            }
        });
    }

    public SetToken(token?: string) {
        this.vue.Api.Main.Client.defaults.headers["X-Auth-Header"] = token || UserModule.Auth.Token;
    }

    /**
     * Login
     * @param {string} token
     * @returns {Promise<IAuthResponse>}
     */
    public async Login(token: string): Promise<IAuthResponse> {
        const res = await this.vue.Api.Main.Client.get<IAuthResponse>("/private/tool/login", { headers: { "X-Auth-Header": token } });

        return res.data;
    }

    /**
     * Logout
     * @returns {Promise<IResponse>}
     */
    public async Logout(): Promise<IResponse> {
        // const res = await this.vue.Api.Main.Client.post<IResponse>("logout");

        UserModule.SetAuth(undefined);

        // return res.data;
        return { status: "OK" };
    }

    /**
     * Check token
     * @param {boolean} clearIfBadToken
     * @returns {Promise<boolean>}
     */
    public async CheckToken(clearIfBadToken = false): Promise<boolean> {
        if (!UserModule.Auth.Token) return false;

        const res = await this.Login(UserModule.Auth.Token);
        const isOK = res.status === "OK";

        if (!isOK && clearIfBadToken) {
            await UserModule.SetAuth(undefined);
        }

        return isOK;
    }
}
