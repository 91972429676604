import Vue from "vue";

import Router from "@/router";
import Store from "@/store/";

// Modules
import { AppModule } from "@/store/modules/App";
import { UserModule } from "@/store/modules/User";


export default (vue: typeof Vue, router: typeof Router, store: typeof Store): void => {
    vue.prototype.$MStore = vue.MStore = {
        App: AppModule,
        User: UserModule,
    };
};
